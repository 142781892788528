import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { RoomsComponent } from './rooms/rooms.component';
import { ListingComponent } from './rooms/listing/listing.component';
import { DetailComponent } from './rooms/detail/detail.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { InterceptorService } from './services/interceptor.service';
import { ErrorInterceptor } from './services/error.interceptor';
import { EditRoomComponent } from './edit-room/edit-room.component';
import { AdminComponent } from './admin/admin.component';
import { RoomsListComponent } from './admin/rooms-list/rooms-list.component';
import { RoomDetailComponent } from './admin/room-detail/room-detail.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ContactComponent } from './contact/contact.component';
import { PaymentPlansComponent } from './payment-plans/payment-plans.component';
import { PayNowComponent } from './pay-now/pay-now.component';
import { PaySuccessComponent } from './pay-success/pay-success.component';
import { PayFailedComponent } from './pay-failed/pay-failed.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutUsComponent,
    RoomsComponent,
    ListingComponent,
    DetailComponent,
    LoginComponent,
    SignupComponent,
    EditRoomComponent,
    AdminComponent,
    RoomsListComponent,
    RoomDetailComponent,
    ContactComponent,
    PaymentPlansComponent,
    PayNowComponent,
    PaySuccessComponent,
    PayFailedComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    IvyCarouselModule,
    HttpClientModule,
    SlickCarouselModule,
    NgbModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
