<div class="spacer_30"></div>
<div class="spacer_30"></div>
<div class="container mw_1440" *ngIf="roomDetail">
    <div class="row">
        <div class="col-md-4 mb-3">
            <div class="profile_left">
                <label class="mb-3">
                    <div class="mb-2">
                        <img style="display: inline-block;width: 100%;max-width: 100px;"
                            src="../../assets/images/user.png" alt="user" />
                    </div>
                    <strong>Name:</strong>
                    <span>
                        {{roomDetail.metadata['created-by'].name}}
                    </span>
                </label>
                <div class="ui_data_1">
                    <p>Current University</p>
                    <strong>{{roomDetail.university}}</strong>
                </div>
                <!-- <div class="ui_data_1">
                    <p>Destination University</p>
                    <strong>University Name</strong>
                </div> -->
            </div>
        </div>
        <div class="col-md-8">
            <div class="detail_room">
                <div style="text-align: right;" class="mb-3">
                    <button type="button" disabled="true" class="btn btn_4 me-3"
                        *ngIf="roomDetail.verified">Approved</button>
                    <button type="button" class="btn btn_2 me-3" *ngIf="!roomDetail.list"
                        (click)="submitForApproval(roomDetail._id)">Submit for Approval</button>
                    <button type="button" class="btn btn_3 me-3" *ngIf="roomDetail.list"
                        (click)="unlistProperty(roomDetail._id)">Unlist Property</button>
                </div>

                <div class="room_detail_ui_1">
                    <ul class="room_image_list" *ngIf="roomDetail.images">
                        <li *ngFor="let roomImage of roomDetail.images">
                            <img [src]="apiUrl + roomImage.url.substring(4)">
                        </li>
                    </ul>
                    <div class="input-group mb-3" style="max-width: 300px;" *ngIf="!roomDetail.list">
                        <input type="file" class="form-control" id="inputGroupFile02" placeholder="choose image"
                            (change)="onChange($event)">
                        <!-- <label class="input-group-text" for="inputGroupFile02">Upload</label> -->
                    </div>
                    <button type="button" class="btn btn-primary" (click)="onUpload()" *ngIf="!roomDetail.list">Upload
                        Image</button>
                </div>
                <div class="room_detail_ui_1">
                    <label for=""><Strong>Room Description</Strong></label>
                    <p>
                        {{roomDetail.description}}
                    </p>
                </div>
                <div class="room_detail_ui_1">
                    <label for="">
                        <strong class="mb-3 mr-2" style="display: inline-block;vertical-align: middle;">Amenities:
                        </strong>
                        <button style="display: inline-block;vertical-align: middle;" type="button"
                            class="btn btn_2 btn-sm mb-3" (click)="editAmenitiesModal(editAmenities)"
                            *ngIf="!roomDetail.list">Add Amenities <i class="fa fa-edit"></i></button>
                    </label>
                    <div class="amenities_ui_1">
                        <ul class="">
                            <ng-container *ngFor="let amenity of roomDetail.amenities">
                                <li *ngIf="amenity.visibility === true">
                                    <img class="amenity_ui_img" [src]="apiUrl + amenity.icon.url.substring(4)"
                                        alt="">{{amenity.name}}
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
                <div class="input-group mb-3" style="max-width: 300px;" *ngIf="!roomDetail.list">
                    <div>Select document: <input type="file" class="form-control" id="inputGroupFile02"
                            (change)="onChange1($event)"></div>
                    <!-- <label class="input-group-text" for="inputGroupFile02">Upload</label> -->
                </div>
                <button type="button" class="btn btn-primary me-3" (click)="onUpload1()" *ngIf="!roomDetail.list">Upload
                    Room Document </button>
                <button *ngIf="roomDetail.roomDocument" type="button" class="btn btn_2"
                    (click)="onOpenFile(roomDetail.roomDocument)"> <i class="fa fa-eye"></i> View Room Document
                </button>
            </div>
        </div>
    </div>
</div>
<div class="spacer_30"></div>

<ng-template #editAmenities let-close="close" let-d="dismiss" let-modal>
    <div class="modal-header">
        <h4 class="">Edit Amenities</h4>
    </div>
    <div class="modal-body">
        <!-- <form [formGroup]="amenitiesForm">
            <div class="form-check">
                <input class="form-check-input" formControlName="a1" type="checkbox" value="24*7 Hot water">
                <label class="form-check-label" for="flexCheckDefault">
                    TV
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" formControlName="a2" type="checkbox" value="AC">
                <label class="form-check-label" for="flexCheckChecked">
                    Toilet/Bathroom
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" formControlName="a3" type="checkbox" value="Parking Space">
                <label class="form-check-label" for="flexCheckDefault">
                    Storage Area
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" formControlName="a4" type="checkbox"
                    value="Nearby Restaurant Available">
                <label class="form-check-label" for="flexCheckChecked">
                    Balcony
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" formControlName="a5" type="checkbox" value="Ro Water">
                <label class="form-check-label" for="flexCheckDefault">
                    Coffee Machine
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" formControlName="a6" type="checkbox" value="Refrigerator">
                <label class="form-check-label" for="flexCheckChecked">
                    Elevator
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" formControlName="a7" type="checkbox" value="Free Wi-Fi">
                <label class="form-check-label" for="flexCheckDefault">
                    Dish Washer
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" formControlName="a8" type="checkbox" value="Nearby hospital">
                <label class="form-check-label" for="flexCheckChecked">
                    Microwave
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" formControlName="a9" type="checkbox" value="Park">
                <label class="form-check-label" for="flexCheckDefault">
                    In House Washing Machine
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" formControlName="a10" type="checkbox" value="Power backup">
                <label class="form-check-label" for="flexCheckChecked">
                    Bicycle Parking
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" formControlName="a11" type="checkbox" value="Pet Allowed">
                <label class="form-check-label" for="flexCheckChecked">
                    Pet Allowed
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" formControlName="a12" type="checkbox" value="Furnished">
                <label class="form-check-label" for="flexCheckChecked">
                    Furnished
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" formControlName="a13" type="checkbox" value="Shared Room">
                <label class="form-check-label" for="flexCheckChecked">
                    Shared Room
                </label>
            </div>
        </form> -->
        <form [formGroup]="amenitiesForm">
            <div *ngFor="let amenity of amenitiesList">
                <div class="form-check" *ngIf="amenity.type === 'checkbox'">
                    <input class="form-check-input" type="checkbox" value="{{amenity.name}}" formControlName="{{amenity.id}}" id="{{amenity.id}}">
                    <label class="form-check-label" for="{{amenity.id}}">{{amenity.name}}</label>
                </div>
            </div>
        </form>
        <span layout="row"><hr flex/></span>
        <form [formGroup]="amenitiesForm">
            <div *ngFor="let amenity of amenitiesList">
                <div *ngIf="amenity.type === 'radio'">
                    <label>Please select {{amenity.name}}:</label>
                    <div class="form-check" *ngFor='let option of amenity.options;let i = index'>
                        <input class="form-check-input" type="radio" value="{{option}}" formControlName="{{amenity.id}}" id="{{amenity.id}}">
                        <label class="form-check-label" for="{{amenity.id}}">{{option}}</label>
                    </div>
                </div>
            </div>
        </form>
        <div>
            <button type="button" class="btn btn-primary me-3" (click)="updateAmenities()">Update Amenities</button>
            <button type="button" (click)="close()" class="btn btn-danger">Cancel</button>
        </div>
    </div>
</ng-template>