import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private _http: HttpClient, public router: Router) { }

  getAllRoomsList(body) {
    return this._http.post(`${environment.apiUrl}` + '/room/advanced/list', body);
  }

  verifyRoom(body, id){
    return this._http.put(`${environment.apiUrl}` + '/room/' + id, body);
  }
  
}
