<section class="terms_and_conditions_section">
    <div class="container mw_1440">
        <div class="about_inner">
            <h1 class="text-center terms_and_conditions_main_header"><u>COOKIE POLICY</u></h1>

            <h2>What Are Cookies</h2>
            <p>As is common practice with almost all professional websites this site uses cookies, which are tiny
                files that are downloaded to your computer, to improve your experience. This page describes what
                information they gather, how we use it and why we sometimes need to store these cookies. We will
                also share how you can prevent these cookies from being stored however this may downgrade or 'break'
                certain elements of the site's functionality.</p>

            <br /><br />

            <h2>How We Use Cookies</h2>
            <p>We use cookies for a variety of reasons detailed below. Unfortunately, in most cases, there are no
                industry standard options for disabling cookies without completely disabling the functionality and
                features they add to this site. It is recommended that you leave on all cookies if you are not sure
                whether you need them or not in case they are used to provide a service that you use.</p>

            <br /><br />

            <h2>Disabling Cookies</h2>
            <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser
                Help for how to do this). Be aware that disabling cookies will affect the functionality of this and
                many other websites that you visit. Disabling cookies will usually result in also disabling certain
                functionality and features of the this site. Therefore it is recommended that you do not disable
                cookies.</p>

            <br /><br />

            <h2>The Cookies We Set</h2>

            <br />

            <h6>Account related cookies</h6>
            <p>If you create an account with us then we will use cookies for the management of the signup process and
                general administration. These cookies will usually be deleted when you log out however in some cases
                they
                may remain afterwards to remember your site preferences when logged out.</p>

            <br />

            <h6>Login related cookies</h6>
            <p>We use cookies when you are logged in so that we can remember this fact. This prevents you from having
                to log in every single time you visit a new page. These cookies are typically removed or cleared when
                you
                log out to ensure that you can only access restricted features and areas when logged in.</p>

            <br />

            <h6>Email newsletters related cookies</h6>
            <p>This site offers newsletter or email subscription services and cookies may be used to remember if you
                are already registered and whether to show certain notifications which might only be valid to
                subscribed/unsubscribed users.</p>

            <br />

            <h6>Forms related cookies</h6>
            <p>When you submit data through a form such as those found on contact pages or comment forms cookies
                may be set to remember your user details for future correspondence.</p>

            <br />

            <h6>Site preferences cookies</h6>
            <p>To provide you with a great experience on this site we provide the functionality to set your
                preferences for how this site runs when you use it. To remember your preferences we need to set
                cookies so that this information can be called whenever you interact with a page that is affected
                by your preferences.</p>
        </div>
    </div>
    <img class="about_top" src="../../assets/images/about_top.png" alt="">
    <img class="aboutr_left" src="../../assets/images/aboutr_left.png" alt="">
    <!-- <img class="about_right_bot" src="../../assets/images/about_right_bot.png" alt=""> -->
</section>