import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoomsService {

  constructor(private _http: HttpClient, public router: Router) { }

  getRoomsList(body){
    return this._http.post(`${environment.apiUrl}` + '/room/list',body);
  }

  getRoomDetail(id){
    return this._http.get(`${environment.apiUrl}` + '/room/' + id);
  }

  updatePropertyDetail(id,body){
    return this._http.put(`${environment.apiUrl}` + '/room/' + id, body);
  }

  notifyUser(body){
    return this._http.post(`${environment.apiUrl}` + '/email/request', body);
  }

  updateRoomImage(id, body) {
    return this._http.put(`${environment.apiUrl}` + '/room/' + id + '/images', body);
  }

  addRoomData(id, body) {
    return this._http.put(`${environment.apiUrl}` + '/room/' + id , body);
  }
}
