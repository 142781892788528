import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RandomService } from '../services/random.service';


@Component({
  selector: 'app-payment-plans',
  templateUrl: './payment-plans.component.html',
  styleUrls: ['./payment-plans.component.scss']
})
export class PaymentPlansComponent implements OnInit {

  constructor(private _randomService: RandomService, public router: Router) { }
  creditPacks;
  selectedPlan='';
  paymentMethod='';

  ngOnInit(): void {
    this.getCreditPacks();
  }

  getCreditPacks(){
    this._randomService.setLoaderStatus(true);
    this._randomService.getPlanList().subscribe(
      (res:any)=>{
        console.log(res);
        this._randomService.setLoaderStatus(false);
        this.creditPacks = res.creditpacks;
      },
      err=>{
        console.log(err);
        this._randomService.setLoaderStatus(false);
      }
    )
  }

  onPlanChange(value){
    console.log(value);
    this.selectedPlan = value;
  }
  onPay(){
    this.router.navigate(['pay-now/' + this.selectedPlan],{queryParams:{paymentMethod: this.paymentMethod}});
  }

  payMethodChange(data){
    this.paymentMethod= data;
  }

}
