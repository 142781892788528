import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { RandomService } from './services/random.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  wrapperActive = false;
  isHeaderVisible = true;
  isLoading= false;

  constructor(
    private router: Router,
    private auth: AuthService,
    public _randomService: RandomService
  ) { }

  ngOnInit() {
    // this.router.events.subscribe(
    //   events => {
    //     if (events instanceof NavigationEnd) {
    //       if (events.url.split('/')[1] == 'auth') {
    //         this.wrapperActive = false;
    //       }
    //       else {
    //         this.wrapperActive = true;
    //       }
    //     }
    //   }
    // );
    this._randomService.isLoading.subscribe(
      data=>{
        this.isLoading = data;
      }
    )
    this.router.events.subscribe(
      events => {
        if (events instanceof NavigationEnd){
          if (events.url.includes('login')){
            this.isHeaderVisible = false;
          }
          else{
            this.isHeaderVisible = true;
          }
          
        }

      }
    );
    this.auth.isAuthenticated();

    if (this.auth.isAuthenticated) {
      
    }


  }
}
