<div class="contact_us_ui" style="background-image: url('../../assets/images/half_img.png');">
    <div class="container" style="max-width: 500px;">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                   
                    <div class="col-md-12 mb-4">
                        <div class="form_section_contcat">
                            <form class="form_ui_outer card form-light-yellow" [formGroup]="contactForm">
                                <div class="mb-3" style="text-align: center;">
                                    <h6>Contact us</h6>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Name</label>
                                    <input type="text" class="form-control custom_input_1"  formControlName="name"/>
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Email address</label>
                                    <input type="email" class="form-control custom_input_1" id="email" formControlName="email"/>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Message</label>
                                    <textarea type="text" class="form-control custom_input_1" formControlName="message"></textarea>
                                </div>
                                <div class="mb-3" style="text-align: center;">
                                    <button type="button" class="btn btn_2" [disabled]="!contactForm.valid" (click)="onSubmit()">Submit</button>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h6>
                            Contact us
                        </h6>
                        <div>
                            <p class="contact_time">
                                <!-- <label for="">Monday - Friday</label> -->
                                <label for="">9am - 5pm CET</label>
                            </p>
                            <!-- <a class="w-100 contact_link" href="tel:+9123458">+9123458</a> -->
                            <a class="w-100 contact_link" href="mailto:xyz@gmail.com">xyz@gmail.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>