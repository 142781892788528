import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private _http: HttpClient, public router: Router) { }

  getSession(body) {
    return this._http.post(`${environment.apiUrl}` + '/payment',body);
  }
}
