<section class="terms_and_conditions_section">
    <div class="container mw_1440">
        <div class="about_inner">
            <h1 class="text-center terms_and_conditions_main_header"><u>PRIVACY POLICY</u></h1>

            <p>At Room swap, accessible from https://............one of our main priorities is the privacy of our
                visitors. This Privacy Policy
                document contains types of information that is collected and recorded by Room swap and how we use it.
                This Privacy Policy applies only to our online activities and is valid for visitors to our website with
                regards to the information
                that they share and/or we
                collect at Room swap. This policy is not applicable to any information collected offline or via channels
                other than this website.</p>

            <br /><br />

            <h2>Consent</h2>
            <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                Information we collect
                The personal information that you are asked to provide, and the reasons why you are asked to provide it,
                will be made clear to you
                at the point we ask you to provide your personal information.
                If you contact us directly, we may receive additional information about you such as your name, email
                address, phone number, the
                contents of the message and/or attachments you may send us, and any other information you may choose to
                provide.
                When you register for an Account, we may ask for your contact information, including items such as name,
                company name, address,
                email address, and telephone number.
                How we use your information</p>
            <p>We use the information we collect in various ways, including to:</p>
            <ul>
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>Understand and analyse how you use our website</li>
                <li>Develop new products, services, features, and functionality</li>
                <li>Communicate with you, either directly or through one of our partners,
                    including for customer service, to provide you with updates and other
                    information relating to the website, and for marketing and promotional
                    purposes</li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
            </ul>

            <br /><br />

            <h2>GDPR Data Protection Rights</h2>
            <p>We would like to make sure you are fully aware of all of your data protection rights.
                Every user is entitled to the following:
                The right to access – You have the right to request copies of your data. We may charge
                you a small fee for this service.
                The right to rectification – You have the right to request that we correct any information
                you believe is inaccurate. You also have the right to request that we complete the information
                you believe is incomplete.
                The right to erasure – You have the right to request that we erase your data, under certain conditions.
                The right to restrict processing – You have the right to request that we restrict the processing
                of your data, under certain conditions.
                The right to object to processing – You have the right to object to our processing of your data,
                under certain conditions.
                The right to data portability – You have the right to request that we transfer the data that
                we have collected to another organization, or directly to you, under certain conditions.
                If you make a request, we have one month to respond to you. If you would like to exercise
                any of these rights, please contact us.</p>

            <br /><br />

            <h2>Children's Information</h2>
            <p>Another part of our priority is adding protection for children while using the internet.
                We encourage parents and guardians to observe, participate in, and/or monitor and guide their online
                activity.
                Room swap does not knowingly collect any Personal Identifiable Information from children under the age
                of 13.
                If you think that your child provided this kind of information on our website, we strongly encourage you
                to
                contact us immediately and we will do our best efforts to promptly remove such information from our
                records.</p>
        </div>
    </div>
    <img class="about_top" src="../../assets/images/about_top.png" alt="">
    <img class="aboutr_left" src="../../assets/images/aboutr_left.png" alt="">
    <!-- <img class="about_right_bot" src="../../assets/images/about_right_bot.png" alt=""> -->
</section>