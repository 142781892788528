import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rooms-list',
  templateUrl: './rooms-list.component.html',
  styleUrls: ['./rooms-list.component.scss']
})
export class RoomsListComponent implements OnInit {

  constructor(private _adminService: AdminService) { }
  apiUrl = environment.apiUrl;

  roomsList;

  ngOnInit(): void {
    this.getRoomsList();
  }

  getRoomsList() {
    let body = {
      offset: 0,
      limit: 100
    };
    this._adminService.getAllRoomsList(body).subscribe(
      (res: any) => {
        this.roomsList = res.data;
      },
      err => {
        console.log(err);
      }
    )
  }

  verifyRoom(roomId){
    // console.log(roomId);
    this._adminService.verifyRoom({
      verified: true
    },roomId).subscribe(
      res=>{
        this.getRoomsList();
      },
      err=>{
      }
    )
  }

  removeRoomFromList(roomId) {
    // console.log(roomId);
    this._adminService.verifyRoom({
      verified: false
    }, roomId).subscribe(
      res => {
        this.getRoomsList();
      },
      err => {
      }
    )
  }

  onOpenFile(roomDocument) {
    window.open(this.apiUrl + roomDocument.url.substring(4), '_blank');

  }

}
