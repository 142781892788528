<!--<div class="progress-container">-->
<!--    <div class="progress-bar" id="scrollBar"></div>-->
<!--  </div>-->
<app-header *ngIf="isHeaderVisible"></app-header>
<div class="wrapper">
    <router-outlet></router-outlet>
</div>
<app-footer *ngIf="isHeaderVisible"></app-footer>
<div class="loader_div" *ngIf="isLoading">
    <img src="../assets/images/Spinner-1s-200px.svg" alt="loader" />
</div>
