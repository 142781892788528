<div class="container min_height">
    <div class="row">
        <div class="col-md-6 offset-md-3 my-5">
            <div class="card text-center  p-5 mt-5">
                <img class="d-block mx-auto my-3" src="../../assets/images/close.png" alt="" style="max-width: 80px;" />
                <div>
                    oops ! Payment Failed
                </div>
            </div>
        </div>
    </div>
</div>