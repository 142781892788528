import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private _authService: AuthService, private router: Router ) { }
  isLoggedIn= false;
  credits = 0;

  userType;

  ngOnInit(): void {
    this._authService.user.subscribe(
      (res)=>{
        // console.log(res);
        if(res){
          this.isLoggedIn=res;
        }
        else{
          this.isLoggedIn = res;
        }
      }
    )
    this._authService.checkCredits();
    this.credits = this._authService.userCredits;
    console.log(this.credits);
    this._authService.updatedUserCredits.subscribe(
      (data:any)=>{
        this.credits = data;
      }
    )
    console.log(this.credits);

    this.userType = this._authService.userType;
    console.log(this.credits);
    this._authService.updatedUserType.subscribe(
      (data: any) => {
        this.userType = data;
      }
    )
  }


  logoutUser() {
    localStorage.clear();
    this.router.navigate(['/login']);
    this._authService.updateUser(this._authService.isAuthenticated());
    this._authService.updateUserType(JSON.parse(localStorage.getItem('userData'))['user-type']);
  }

}
