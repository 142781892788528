import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { RoomDetailComponent } from './admin/room-detail/room-detail.component';
import { RoomsListComponent } from './admin/rooms-list/rooms-list.component';
import { ContactComponent } from './contact/contact.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { EditRoomComponent } from './edit-room/edit-room.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { PayFailedComponent } from './pay-failed/pay-failed.component';
import { PayNowComponent } from './pay-now/pay-now.component';
import { PaySuccessComponent } from './pay-success/pay-success.component';
import { PaymentPlansComponent } from './payment-plans/payment-plans.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DetailComponent } from './rooms/detail/detail.component';
import { ListingComponent } from './rooms/listing/listing.component';
import { RoomsComponent } from './rooms/rooms.component';
import { AuthGuard } from './services/auth.guard';
import { SignupComponent } from './signup/signup.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'rooms',
    component: RoomsComponent,
    children: [
      {
        path: '',
        redirectTo: 'listing',
        pathMatch: 'full'
      },
      {
        path: 'listing',
        component: ListingComponent
      },
      {
        path: 'detail/:id',
        component: DetailComponent
      }
    ]
  },
  {
    path: 'admin',
    component: RoomsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'room-listing',
        pathMatch: 'full'
      },
      {
        path: 'room-listing',
        component: RoomsListComponent
      },
      {
        path: 'room-detail',
        component: RoomDetailComponent
      }
    ]
  },
  {
    path: 'plans',
    component: PaymentPlansComponent
  },
  {
    path: 'pay-now/:orderId',
    component: PayNowComponent
  },
  {
    path: 'pay-success',
    component: PaySuccessComponent
  },
  {
    path: 'pay-failed',
    component: PayFailedComponent
  },
  // {
  //   path: 'contact-us',
  //   component: ContactComponent
  // },
  {
    path: 'edit-room',
    component: EditRoomComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
