import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { RandomService } from '../services/random.service';
import { RoomsService } from '../services/rooms.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-edit-room',
  templateUrl: './edit-room.component.html',
  styleUrls: ['./edit-room.component.scss']
})
export class EditRoomComponent implements OnInit {
  file: any;
  file1: any;

  constructor(
    private _roomService: RoomsService,
    private _randomService: RandomService,
    public router: Router,
    public _modalService: NgbModal,
    public fb: FormBuilder
  ) { }
  apiUrl = environment.apiUrl;

  roomId;

  roomDetail;

  ngOnInit(): void {
    this.roomId = JSON.parse(localStorage.getItem('userData')).roomId;
    // console.log(this.roomId);
    this.getRoomDetail(this.roomId);
    this.getAmenitiesList();
  }

  getRoomDetail(id) {
    this._randomService.setLoaderStatus(true);
    this._roomService.getRoomDetail(id).subscribe(
      (res: any) => {
        console.log(res);
        this.roomDetail = res;
        this._randomService.setLoaderStatus(false);
      },
      err => {
        console.log(err);
        this._randomService.setLoaderStatus(false);
      }
    )
  }

  submitForApproval(id) {
    this._randomService.setLoaderStatus(true);
    var body = {
      list: true
    }
    this._roomService.updatePropertyDetail(id, body).subscribe(
      (res: any) => {
        console.log(res);
        this.getRoomDetail(this.roomId);
        this._randomService.setLoaderStatus(false);
        alert('Your Listing has been submitted for verification.')
      },
      err => {
        console.log(err);
        this._randomService.setLoaderStatus(false);
      }
    )
  }
  unlistProperty(id) {
    this._randomService.setLoaderStatus(true);
    var body = {
      list: false
    }
    this._roomService.updatePropertyDetail(id, body).subscribe(
      (res: any) => {
        console.log(res);
        this.getRoomDetail(this.roomId);
        this._randomService.setLoaderStatus(false);
      },
      err => {
        console.log(err);
        this._randomService.setLoaderStatus(false);
      }
    )
  }

  onChange(event) {
    this.file = event.target.files[0];
  }

  onChange1(event) {
    this.file1 = event.target.files[0];
  }

  // OnClick of button Upload
  onUpload() {
    //this.loading = !this.loading;
    this._randomService.setLoaderStatus(true);
    console.log(this.file);
    this._randomService.upload(this.file).subscribe(
      (data: any) => {
        console.log(data);
        this._randomService.setLoaderStatus(false);
        this.createRoomImage(data);
        // if (this.roomDetail.images) {

        //   this.updateRoomImage(data);
        // }
        // else {
        //   this.createRoomImage(data);
        // }
        // if (typeof (event) === 'object') {
        //   // Short link via api response
        //   this.shortLink = event.link;

        //   this.loading = false; // Flag variable 
        // }
      }
    );
  }

  onUpload1() {
    //this.loading = !this.loading;
    this._randomService.setLoaderStatus(true);
    console.log(this.file1);
    this._randomService.uploadFile(this.file1).subscribe(
      (data: any) => {
        console.log(data);
        const body = {
          roomDocument: data
        }
        this.updateRoomDetail(body);
        this._randomService.setLoaderStatus(false);


      }
    );
  } 1

  updateRoomImage(data: any) {

    this._roomService.updateRoomImage(this.roomId, data).subscribe((data) => {
      // this.ngOnInit
    })

  }

  createRoomImage(data) {
    this._randomService.setLoaderStatus(true);
    const body = {
      images: [
        data
      ]
    }
    this._roomService.addRoomData(this.roomId, body).subscribe((data) => {
      // this.ngOnInit
      this.getRoomDetail(this.roomId);
      this._randomService.setLoaderStatus(false);
    })

  }

  updateRoomDetail(body) {
    this._randomService.setLoaderStatus(true);
    this._roomService.addRoomData(this.roomId, body).subscribe((data) => {
      // this.ngOnInit
      this.getRoomDetail(this.roomId);
      this._randomService.setLoaderStatus(false);
    })
  }

  onOpenFile(roomDocument) {
    window.open(this.apiUrl + roomDocument.url.substring(4), '_blank');

  }

  editAmenitiesModal(modalRef) {
    // console.log(amenitiesData);
    this._modalService.open(modalRef, {
      ariaLabelledBy: 'editAmenities',
      centered: true,
      size: 'md'
    }).result.finally(
      () => {

      }
    )
  }

  // checkAndReturnSelectedAmenity(id){
  //   for(let i = 0; i<this.roomDetail.amenities.length; i++){
  //     let returnableValue:boolean;
  //     if(this.roomDetail.amenities[i].id == id && this.roomDetail.amenities[i].visibility == true){
  //       console.log('found');
  //       returnableValue = true;
  //       break;
  //     }
  //     else{
  //       returnableValue = false;
  //     }
  //     return returnableValue;
  //   }

  // }






  amenitiesList = []

  amenitiesForm = this.fb.group({
    a1: [],
    a2: [],
    a3: [],
    a4: [],
    a5: [],
    a6: [],
    a7: [],
    a8: [],
    a9: [],
    a10: [],
    a11: [],
    a12: [],
    a13: []
  });
  get amenitiesFormControl() { return this.amenitiesForm.controls }

  updateAmenities() {
    console.log("amenitiesList (JSON) = ", JSON.stringify(this.amenitiesList));
    this._randomService.setLoaderStatus(true);
    let generatedAmenitiesList = [];
    console.log("this.amenitiesForm.value = ", this.amenitiesForm.value);
    let amData = this.amenitiesForm.value;
    for (var key in amData) {
      if (amData.hasOwnProperty(key)) {
        // var val = amData[key];
        // console.log(val);
        let itemData = amData[key];
        if (itemData) {
          let type = (_.find(this.amenitiesList, { id: key })).type;
          let name = "Default Amenity name";
          if (type === 'checkbox') {
            name = (_.find(this.amenitiesList, { id: key })).name;
          } else if (type === 'radio') {
            name = itemData;
          }
          let addData = {
            id: key,
            visibility: true,
            name: name,
            icon: (_.find(this.amenitiesList, { id: key })).image
          }
          generatedAmenitiesList.push(addData);
        }
      }
    }
    console.log("generatedAmenitiesList = ", generatedAmenitiesList);
    let amenities = {
      amenities: generatedAmenitiesList
    }
    this._roomService.updatePropertyDetail(this.roomId, amenities).subscribe(
      (res: any) => {
        console.log('sucess');
        this.getRoomDetail(this.roomId);
        this.amenitiesForm.reset();
        this._modalService.dismissAll();
        this._randomService.setLoaderStatus(false);
      },
      err => {
        console.log(err);
        this._randomService.setLoaderStatus(false);
      }
    )
  }

  getAmenitiesList() {
    this._randomService.getAmenitiesList().subscribe(
      (res: any) => {
        console.log(res);
        this.amenitiesList = res.amenities;
      },
      err => {

      }
    )
  }

}
