<div class="container mw_1440">
    <div class="spacer_30">
    </div>
    <div class="rooms_listing" >
        <div class="roomsList_ui_1" *ngFor="let room of roomsList;index as i;">
            <div class="row">
                <div class="col-md-4">
                    <div class="img_section">
                        <carousel [cellsToShow]="1" [autoplay]="false" [borderRadius]="15" [overflowCellsLimit]="2">
                            <div class="carousel-cell" *ngFor="let roomImage of room.images">
                                <img [src]="apiUrl + roomImage.url.substring(4)">
                            </div>
                        </carousel>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="univ_detail">
                        <button *ngIf=" room.verified" class="btn btn-success me-3 mb-3" disabled="true" type="button">Room Verified</button>
                        <h3>{{room.wing}}</h3>
                        <!-- <p> <strong>Created By: </strong> {{room.metadata.created-by.name}}</p>
                        <p> <strong>Modified By: </strong> {{room.metadata.modified-by.name}}</p> -->
                        <p class=""><b>Available From: </b>Aug 12,2021</p>
                        <p class=""><b>Duration: </b>2 Months</p>
                        <p *ngIf="room.roomListingPrice" class=""><b>Price: </b>{{room?.roomListingPrice | currency:'SEK'}}</p>
                        <div class="amenities_ui_1">
                            <ul>
                                <li *ngFor="let roomAmenities of room.amenities" placement="top"
                                [ngbTooltip]="roomAmenities.description">{{roomAmenities.name}}</li>
                            </ul>
                        </div>
                        <button *ngIf="room.roomDocument" type="button" class="btn btn_2 mt-3"
                            (click)="onOpenFile(room.roomDocument)">View Room Document </button>
                        <!-- <button class="btn btn-primary mt-3 me-3" type="button" (click)="viewDetail(room._id)"> <i class="fa fa-view"></i> View Detail</button> -->
                        <button *ngIf=" !room.verified" class="btn btn_2 mt-3" type="button" (click)="verifyRoom(room._id)">Verify Now</button>
                        <button *ngIf="room.verified" class="btn btn_3 mt-3" type="button" (click)="removeRoomFromList(room._id)">Remove From List</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>