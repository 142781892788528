import { HttpHandler, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { exhaustMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  constructor(public authService: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // return this.authService.user.pipe(
    //   take(1), exhaustMap(
    //     user => {
    //       if (user) {
    //         const modifiedRequest = req.clone({ params: new HttpParams().set('auth', user.token) });
    //         return next.handle(modifiedRequest);
    //       }
    //       else {
    //         return next.handle(req);
    //       }
    //     }
    //   )
    // );

    return this.authService.user.pipe(
      take(1), exhaustMap(
        user => {
          if (user) {
            const modifiedRequest = req.clone({
              //  headers: req.headers.set('Authorization','Bearer ' + user) ,
              // utcOffset: localStorage.getItem('UTCOffset')
              setHeaders: {
                Authorization: `Bearer ${user}`,
              }
            });
            return next.handle(modifiedRequest);
          }
          else {
            return next.handle(req);
          }
        }
      )
    );

  }
}
