import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user = new BehaviorSubject(null);
  token;
  userData;
  userDetails = new Subject();

  userType;
  updatedUserType = new BehaviorSubject(null);

  userCredits;
  updatedUserCredits = new Subject();

  constructor(private _http: HttpClient, public router: Router) { }

  loginUser(body) {
    this._http.post(`${environment.apiUrl}` + '/auth/' + body.username, body).subscribe(
      async (res: any) => {
        let roomId = res.roomId;
        let roomVerificationStatus = await this.getRoomVerificationStatus(roomId)
        res.roomVerificationStatus = roomVerificationStatus;
        localStorage.setItem('userData', JSON.stringify(res));
        this.updateUser(res.token);
        this.userDetails.next(res.token);
        if (this.isAuthenticated) {
          this.getUserDetail(res.username);
          this.updateUserType(JSON.parse(localStorage.getItem('userData'))['user-type']);
          this.router.navigate(['/rooms/listing']);
        }
      },
      err => {
        console.log(err);
      }
    )
  }

  async getRoomVerificationStatus(roomId) {
    let roomVerificationStatus = false;
    try {
      let res = <any>await this._http.get(`${environment.apiUrl}` + '/room/' + roomId).toPromise();
      let list = res.list;
      let verified = res.verified;
      roomVerificationStatus = list && verified;
    } catch (err) {
      console.error("Caught error while calling room details API", err)
    }
    console.log("roomVerificationStatus=", roomVerificationStatus)
    return roomVerificationStatus
  }

  // check authentication of user.
  public isAuthenticated(): boolean {
    if (localStorage.getItem('userData')) {
      this.token = JSON.parse(localStorage.getItem('userData'))['token'];
      // console.log('token' + this.token);
      this.updateUser(this.token);
      this.updateUserType(JSON.parse(localStorage.getItem('userData'))['user-type']);
      this.user.subscribe(
        data => {
          this.userData = data;
        }
      )
      if (this.userData == null || this.userData == '') {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return false;
    }
  }


  // Update token
  updateUser(token) {
    this.user.next(token);
  }

  updateUserType(updateUserType) {
    this.userType = updateUserType;
    this.updatedUserType.next(this.userType);
  }

  getToken() {
    return this.token;
  }

  getUserDetail(username) {
    this._http.get(`${environment.apiUrl}` + '/user/' + username).subscribe(
      async (res: any) => {
        let roomId = res.roomId;
        let roomVerificationStatus = await this.getRoomVerificationStatus(roomId)
        res.roomVerificationStatus = roomVerificationStatus;
        localStorage.setItem('userDetail', JSON.stringify(res));
        this.updateCredits(res.credits);
      },
      err => {
        console.log(err);
      }
    )
  }

  checkCredits() {
    this.getUserDetail(JSON.parse(localStorage.getItem('userData')).username);
  }
  updateCredits(credits) {
    this.userCredits = credits;
    this.updatedUserCredits.next(this.userCredits);
  }

  signUpUser(body) { return this._http.post(`${environment.apiUrl}` + '/auth/signup', body) }

}
