import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { RoomsService } from 'src/app/services/rooms.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  apiUrl = environment.apiUrl;
  slideImageConfig = { 
    slidesToShow: 2, 
    slidesToScroll: 1,
    dots:true
  };

  constructor(public route: ActivatedRoute, private _roomService: RoomsService, private auth : AuthService,public _modalService: NgbModal, public router: Router) { }
  roomDetail;
  ngOnInit(): void {
    this.route.params.subscribe(
      id=>{
        console.log(id.id);
        this.getRoomDetail(id.id);
      }
    )
  }
  slickInit(e) {
    console.log('slick initialized');
  }
  getRoomDetail(id){
    this._roomService.getRoomDetail(id).subscribe(
      (res:any)=>{
        this.roomDetail = res;
        console.log(res);
      },
      err=>{
        console.log(err);
      }
    )
  }

  openSendMessagePopup(popupRef){
    this.auth.user.subscribe(
      data=>{
        if(data){
          console.log(data);
          this._modalService.open(popupRef, {
            ariaLabelledBy: 'sendMessage',
            centered: true,
            size: 'md'
          }).result.finally(
            () => {
              
            }
          )
        }
        else{
          this.router.navigate(['/signup']);
        }
      }
    )
    
  }

  NotifyUser(){
    var body={
      sender: JSON.parse(localStorage.getItem('userData')).username,
      receiver: this.roomDetail.roomUserId,
      message: `Room Swap Request`
    }
    console.log(this.roomDetail);
    this._roomService.notifyUser(body).subscribe(
      (res:any)=>{
        alert('A mail has been sent to the property user.')
        this.auth.checkCredits();
        // this.ngOnInit();
        this._modalService.dismissAll();
      },
      err=>{
        console.log(err);
      }
    )
  }

  checkRoomVerificationStatus() {
    if(localStorage.getItem('userData')){
      return JSON.parse(localStorage.getItem('userData')).roomVerificationStatus;
    }
    else{
      return false;
    }
    
  }
}
