<!-- <div class="main_container_fh" style="height: 100vh;">
    <div class="h-100 d-inline-block">
        <div class="card p-3 position-absolute top-50 start-50 translate-middle card_ui_1 w-100"
            style="max-width: 400px;">
            <form [formGroup]="loginForm">
                <h3 class="mb-3">login</h3>
                <div class="mb-3">
                    <label for="username" class="form-label">Username</label>
                    <input type="email" class="form-control" id="username" formControlName="username" />
                </div>
                <div class="mb-3">
                    <label for="password" class="form-label">Password</label>
                    <input type="password" class="form-control" id="password" formControlName="password" />
                </div>
                <button type="submit" class="btn btn-primary" (click)="loginUser()">Login</button>
            </form>
        </div>
    </div>
</div> -->
<div class="container-fluid bckgnd-img">
    <div class="row">
        <div class="offset-md-3 col-md-6">
            <div class="fh_100">
                <form class="form_ui_0ne" [formGroup]="loginForm">
                    <a class="text-primary" [routerLink]="['/']"> <i class="fa fa-arrow-left"></i> Back</a>
                    <h2 class="mb-3">login</h2>
                    <div class="mb-3">
                        <label for="username" class="form-label">Username</label>
                        <input type="email" class="form-control" id="username" formControlName="username" />
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">Password</label>
                        <input type="password" class="form-control" id="password" formControlName="password" />
                    </div>
                    <button type="submit" class="btn btn-primary" (click)="loginUser()">Login</button>
                </form>
            </div>
        </div>
    </div>
</div>