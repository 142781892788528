<section class="terms_and_conditions_section">
    <div class="container mw_1440">
        <div class="about_inner">
            <h1 class="text-center terms_and_conditions_main_header"><u>TERMS AND CONDITIONS</u></h1>

            <p>Welcome to Room swap!
                These terms and conditions outline the rules and regulations for the use of the room swaps website,
                located at https://.
                By accessing this website, we assume you accept these terms and conditions. Do not continue to use Room
                swap if you do
                not agree to take all of the terms and conditions stated on this page.
                The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice
                and all Agreements:
                "Client", "You" and "Your" refers to you, the person who logs on this website and is compliant to the
                Company’s terms and
                conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties",
                or
                "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and
                consideration of payment
                necessary to undertake the process of our assistance to the Client in the most appropriate manner for
                the express purpose
                of meeting the Client’s needs in respect of the provision of the Company’s stated services, in
                accordance with and subject
                to, prevailing law. Any use of the above terminology or other words in the singular, plural,
                capitalization and/or he/she
                or they, are taken as interchangeable and therefore as referring to the same.
                Your use of the Services
                The use of the Services is limited to persons who are students and have been admitted to an exchange
                programme.
                By registering an account on the Website and using the Services, you certify that you are a student When
                you use
                the Services to publish an advertisement for rental of a room, you guarantee that you have all the
                necessary
                permits to rent out the room in question.
                Room swap reserves the right to refuse to provide the Services to persons who have previously violated
                the
                Terms of Use, the terms otherwise provided by room swap, or other laws and/or rules. As a user, you
                undertake
                to comply with applicable discrimination laws and accepted ethical standards when using the Services,
                including
                but not limited to the content of advertisements you publish on the Website.</p>

            <br /><br />

            <h2>License</h2>
            <p>Unless otherwise stated, Roomswap and/or its licensors own the intellectual property rights for all
                material
                on Room swap. All intellectual property rights are reserved. You may access this from Room swap for your
                personal
                use subject to restrictions set in these terms and conditions.</p>
            <p>You must not:</p>
            <ul>
                <li>Republish material from Room swap</li>
                <li>Sell, rent or sub-license material from Room swap</li>
                <li>Reproduce, duplicate or copy material from Room swap</li>
                <li>Redistribute content from Room swap</li>
            </ul>
            <p>This Agreement shall begin on the date hereof.</p>
            <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in
                certain areas
                of the website. Roomswap does not filter, edit, publish or review Comments before their presence on the
                website.
                Comments do not reflect the views and opinions of Roomswap, its agents and/or affiliates. Comments
                reflect the views
                and opinions of the person who posts their views and opinions. To the extent permitted by applicable
                laws, Roomswap
                shall not be liable for the Comments or any liability, damages or expenses caused and/or suffered as a
                result of any
                use of and/or posting of and/or appearance of the Comments on this website.
                Roomswap reserves the right to monitor all Comments and to remove any Comments which can be considered
                inappropriate,
                offensive or causes a breach of these Terms and Conditions.</p>
            <p>You warrant and represent that:</p>
            <ul>
                <li>You are entitled to post information on our website and have all necessary licenses and consents to
                    do so;</li>
                <li>The Comments do not invade any intellectual property right, including without limitation copyright,
                    patent or trademark of any third party;</li>
                <li>The Comments do not contain any defamatory, libellous, offensive, indecent or otherwise unlawful
                    material which is an invasion of privacy</li>
                <li>The Comments will not be used to solicit or promote business or custom or present commercial
                    activities or unlawful activity.</li>
            </ul>
            <p>You hereby grant Roomswap a non-exclusive license to use, reproduce, edit and authorize others to use,
                reproduce and
                edit any of your Comments in all forms, formats or media.
                No use of Room swap’s logo or other artwork will be allowed for linking absent a trademark license
                agreement.</p>

            <br /><br />

            <h2>Content Liability</h2>
            <p>We shall not be held responsible for any content that appears on your Website. You agree to protect and
                defend
                us against all claims that are rising on your Website. No link(s) should appear on any Website that may
                be
                interpreted as libellous, obscene or criminal, or which infringes, otherwise violates, or advocates the
                infringement or other violation of, any third party rights.</p>

            <br /><br />

            <h2>Disclaimer</h2>
            <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and
                conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
            <ul>
                <li>limit or exclude our or your liability for death or personal injury;</li>
                <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
            </ul>
            <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer:
                (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer,
                including liabilities arising in contract, in tort and for breach of statutory duty.
                As long as the website and the information and services on the website are provided, we will not be
                liable for any loss or damage of any nature.</p>

            <br /><br />

            <h2>Register a user account</h2>
            <p>To fully access and use the content on the Website and the Services, you need to register a personal user
                account.
                You create a user account by using your email address and a unique password and provide an admission
                letter from your
                current university.</p>
            <p>When registering an account with room swap, you undertake to provide complete and correct information.
                You further
                undertake to keep the information for your account up to date so that your information is always
                complete and correct.
                It is only permitted to register one user account per person. You are responsible to keep your login
                information protected
                and strictly confidential to prevent unauthorized persons from gaining access to your user account. Your
                user account is
                personal, and you are responsible for all activity that takes place from or with the use of your
                account.</p>
            <p>If you suspect or have reason to suspect that someone has gained access to your login information, you
                must immediately
                change this information in your account settings on the Website. You also undertake to immediately
                notify room swap at
                info@roomswap.com if anyone has illegally accessed or used your account, or if you have reason to
                suspect that this may
                be the case. By registering an account with us and/or using our Services, you agree to receive
                communication (including
                but not limited to notifications and updates)</p>

            <br /><br />

            <h2>Personal information</h2>
            <p>By using our Services, you understand and agree that we collect and process certain personal information
                about you.
                This is necessary for us to be able to provide and develop the Services. For more information about what
                information
                we collect, how we use your personal information and what opportunities and rights you have, we refer
                you to our privacy
                policy. For other questions and matters regarding personal data, privacy and data protection, please
                contact us at
                info@roomswap.com.</p>

            <br /><br />

            <h2>Advertising</h2>
            <p>For a room to be advertised on the Website, you must follow our rules of advertising. Advertising rules
                are provided
                through the information available on the Website. Information regarding what can be considered illegal
                or improper and
                thus may not appear in advertising should not be considered complete or exhaustive, but only exemplary.
            </p>
            <p>You are responsible for your ad and that the information you include in the ad does not conflict with
                applicable laws
                and regulations or otherwise with accepted ethical standards. Ads may not contain racist, offensive
                content and personal
                information.Room swap reserves the right to review all advertisements and to refuse or remove an
                advertisement because
                it violates room swap rules for advertising,third party copyright, other legal regulation or any other
                of room swap's
                principles.</p>
            <p>Room swap approves every ad to verify proof of being a student or ownership of the home. Room swap
                reserves the right
                to revoke or suspend any user who does not adhere to stipulated rules and regulations. For a
                room/accommodation to be
                advertised on the website, you must follow our rules of advertising.</p>
            <p>Advertising rules are provided through the information available on the Website. You are responsible for
                your ad and
                that the information you include in the ad does not conflict with applicable laws and regulations or
                otherwise with
                accepted ethical standards. Ads may not contain racist or otherwise offensive content. Once a potential
                swap mate has
                confirmed that he or she wants to swap the advertised accommodation. Both parties shall not unilaterally
                increase the
                rent or otherwise change the terms of the rental agreement compared to what is stated in the
                advertisement.</p>

            <br /><br />

            <h2>Rental agreement</h2>
            <p>The agreement must reflect the terms stated in the advertisement which both students have otherwise
                agreed on in their
                communication with each other. Students shall enter an agreement at their own risk. It is the
                responsibility of each party
                to carefully gather as much information as possible before settling to swap accommodations and further
                to ensure that the
                agreement reflects the terms stated in the advertisement and what the parties have agreed upon. Both
                students understand and
                confirm that room swap is in no way a party to the entered rental agreement. They are jointly
                responsible to ensure that the
                necessary insurance is taken out for the residence (including its equipment) that covers the intended
                rental.</p>
            <p>Users agree that they shall not contact another user for any other reason than a home in purpose to enter
                into an agreement.
                They further accept not to provide incorrect, incomplete, or misleading information when using the
                Website or the Services.
                Users will not use the Website or the Services to publish or convey pornography, political opinions,
                offensive material, or
                other material that room swap finds inappropriate; or encourage or assist someone who intends to carry
                out any of the above.
                room swap reserves the right to permanently suspend or temporarily block a user who abuses or uses the
                Services in violation
                of the Terms of Use or other rules, or guidelines issued by room swap.</p>

            <br /><br />

            <h2>Limitation of liability</h2>
            <p>Room swap does not guarantee continuous, uninterrupted, or secure access to the Services. The operation
                of the Website may
                be disrupted by several factors within or beyond room swap control and room swap makes no warranty
                regarding the Website's
                function or availability.
                Room swap cannot be held liable for damages directly or indirectly caused by the use of the Website or
                its content. The website
                is mainly a place for advertising rooms/accommodation for rent and allows users to advertise and/or find
                a room. Room swap has
                no control over and does not participate in the transactions between students. Room swap is not
                responsible for the advertised
                property. Room swap is also not liable for damages due to missing or delayed responses to ads or
                incorrect information in the
                text of the ad.
                A rental agreement is entered into directly between swap mates and it is thus it’s both parties’
                responsibility to carry out the
                verifications, checks and investigations that are needed regarding your counterparty, the property and
                other circumstances. Room
                swap may make changes to the Terms of Use. In the event of changes of an essential nature, you will be
                informed in good time and
                your consent will be obtained.
                Applicable law and settlement of disputes
                Swedish law shall apply to the Terms of Use. Disputes will finally be decided in a Swedish general
                court.</p>
        </div>
    </div>
    <img class="about_top" src="../../assets/images/about_top.png" alt="">
    <img class="aboutr_left" src="../../assets/images/aboutr_left.png" alt="">
    <!-- <img class="about_right_bot" src="../../assets/images/about_right_bot.png" alt=""> -->
</section>