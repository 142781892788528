import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RandomService } from '../services/random.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private fb: FormBuilder, private _randomService: RandomService) { }

  contactForm = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    message: ['', [Validators.required]]
  });

  ngOnInit(): void {
  }

  onSubmit(){
    this._randomService.contactUs(this.contactForm.value).subscribe(
      (res:any)=>{
        console.log(res);
        alert('success');
        this.contactForm.reset();
      },
      err=>{
        console.log(err);
      }
    )
    // console.log(this.contactForm.value);
    
  }

}
