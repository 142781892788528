<section class="about_section">
    <div class="container mw_1440">
        <div class="about_inner">
            <h6>About us</h6>
            <p>
                A website for students by students. The idea for this platform was born after our own studies
abroad. We were excited to be admitted to the new university but soon realised how hard it was
to get a place to stay since we were not guaranteed accommodation by the host university. We
searched on many platforms online, two things were prominent, many websites were not
student-friendly as they were too costly, or we ended up interacting with scammers. Having
experienced such, it was clear that something had to be done. Hence, we came up with this
student-driven platform where only students can meet and swap accommodation having less to
worry about paying a ton of money just to find a place to stay. One student at your desired
destination can take your existing room and you can take theirs. It makes the exchange easier,
and you always have someone who can tell you about things that can be useful in your new
area.
            </p>
        </div>
    </div>
    <img class="about_top" src="../../assets/images/about_top.png" alt="">
    <img class="aboutr_left" src="../../assets/images/aboutr_left.png" alt="">
    <!-- <img class="about_right_bot" src="../../assets/images/about_right_bot.png" alt=""> -->
</section>