import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RandomService } from '../services/random.service';
import { RoomsService } from '../services/rooms.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private fb: FormBuilder, 
    private _roomService: RoomsService, 
    private _randomService: RandomService,
    public router: Router
    ) { }

  searchForm = this.fb.group({
    currentUniversity: ['',[Validators.required]],
    destinationUniversity: ['',[Validators.required]],
    gender: ['']
  });
  apiUrl = environment.apiUrl;
  universityList;
  slideConfig = { 
    slidesToShow: 3, 
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    responsive:[
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  ngOnInit(): void {
    this.getRoomsList();
    this.getUniversityList();
  }
  roomsList;
  getRoomsList() {
    let body = {
      offset: 0,
      limit: 10
    };
    this._roomService.getRoomsList(body).subscribe(
      (res: any) => {
        console.log(res);
        this.roomsList = res.data;
      },
      err => {
        console.log(err);
      }
    )
  }

  searchProperty(){
    console.log(this.searchForm.value);
    let filterBody={
      currentUniversity:this.searchForm.value.destinationUniversity,
      destinationUniversity:this.searchForm.value.currentUniversity,
      gender: this.searchForm.value.gender
    }
    var searchParam = _(filterBody).omitBy(_.isUndefined).omitBy(_.isNull).omitBy(_.isEmpty).value();
    this.router.navigate(['/rooms/listing'],{
      queryParams: searchParam,
      queryParamsHandling: 'merge'
    });
  }

  getUniversityList(){
    this._randomService.getUniversityList().subscribe(
      (res: any) => {
        console.log(res);
        this.universityList = res.universities;
      }
    )
  }
  slickInit(e) {
    console.log('slick initialized');
  }

}
