<div class="footer_main">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6 col-xs-12">
                <img class="footer_image" src="../../assets/images/logo.png" alt="logo" />
            </div>
            <div class="col-md-3 col-sm-6 col-xs-6">
                <ul class="footer_listing">
                    <li>
                        <a routerLink="/home">Home</a>
                    </li>
                    <li>
                        <a routerLink="/about-us">About Us</a>
                    </li>
                    <li>
                        <a routerLink="/contact">Contact us</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-6">
                <ul class="footer_listing">
                    <li>
                        <a routerLink="/terms-and-conditions">Terms and Conditions</a>
                    </li>
                    <li>
                        <a routerLink="/privacy-policy">Privacy Policy</a>
                    </li>
                    <li>
                        <a routerLink="/cookie-policy">Cookie Policy</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-6">
                <ul class="footer_listing">
                    <li>
                        <a href="#" class="fa fa-facebook footer-icon"></a>
                        <a href="#" class="fa fa-twitter footer-icon"></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- <img class="footer_center" src="../../assets/images/footer_center.png" alt="footer_center" /> -->
</div>