import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RandomService {

  isLoading = new BehaviorSubject(false);;

  

  constructor(private _http: HttpClient, public router: Router) { }

  setLoaderStatus(status) {
    this.isLoading.next(status);
  }

  getUniversityList() {
    return this._http.get(`${environment.apiUrl}` + '/constants/universities');
  }

  getAmenitiesList() {
    return this._http.get(`${environment.apiUrl}` + '/constants/amenities');
  }

  getPlanList() {
    return this._http.get(`${environment.apiUrl}` + '/constants/creditpacks');
  }

  upload(file): Observable<any> {

    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append("file", file, file.name);

    // Make http post request over api
    // with formData as req
    return this._http.post(`${environment.apiUrl}` + '/image', formData)
  }
  uploadFile(file): Observable<any> {

    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append("file", file, file.name);

    // Make http post request over api
    // with formData as req
    return this._http.post(`${environment.apiUrl}` + '/file', formData)
  }

  contactUs(body){
    return this._http.post(`${environment.apiUrl}` + '/contact-us/', body)
  }
}
