import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { RoomsService } from 'src/app/services/rooms.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { RandomService } from 'src/app/services/random.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {

  constructor(private _roomService: RoomsService, public _modalService: NgbModal, public router: Router, private route: ActivatedRoute, private fb: FormBuilder, private _randomService: RandomService, private auth: AuthService) { }

  @ViewChild('successPopup', { static: true }) successPopup: ElementRef;

  apiUrl = environment.apiUrl;
  searchForm = this.fb.group({
    currentUniversity: ['', [Validators.required]],
    destinationUniversity: ['', [Validators.required]],
    gender: ['']
  });
  totalCount;
  page = 1;
  pageSize = 10;
  // destinationUniversity;
  universityList;

  roomsList;

  roomData;
  filterBody;

  ngOnInit(): void {
    this.getUniversityList();
    this.route.queryParams
      .subscribe(params => {
        // console.log(params);
        // this.destinationUniversity = params.destinationUniversity;
        this.filterBody = params;
        this.getRoomsList();
      }
      );

  }
  onPageChange(data) {
    console.log(data);
    this.page = data;
    this.getRoomsList();
  }

  getRoomsList() {
    this._randomService.setLoaderStatus(true);
    let body = {
      offset: (this.page - 1) * this.pageSize,
      limit: this.pageSize,
      destinationUniversity: this.filterBody.destinationUniversity,
      currentUniversity: this.filterBody.destinationUniversity,
      gender: this.filterBody.gender
    };
    this._roomService.getRoomsList(body).subscribe(
      (res: any) => {
        console.log(res);
        this.totalCount = res.count;
        this.roomsList = res.data;
        this._randomService.setLoaderStatus(false);
      },
      err => {
        console.log(err);
        this._randomService.setLoaderStatus(false);
      }
    )
  }
  getUniversityList() {
    this._randomService.getUniversityList().subscribe(
      (res: any) => {
        console.log(res);
        this.universityList = res.universities;
      },
      err => {

      }
    )
  }
  openSendMessagePopup(data2, popupRef) {
    this.auth.user.subscribe(
      data => {
        if (data) {
          this.roomData = data2;
          console.log(data);
          this._modalService.open(popupRef, {
            ariaLabelledBy: 'sendMessage',
            centered: true,
            size: 'md'
          }).result.finally(
            () => {

            }
          )
        }
        else {
          this.router.navigate(['/signup']);
        }
      }
    )

  }

  NotifyUser() {
    this._randomService.setLoaderStatus(true);
    var body = {
      sender: JSON.parse(localStorage.getItem('userData')).username,
      receiver: this.roomData.roomUserId,
      message: `Room Swap Request`,
      senderPropertyUrl: `${window.origin}/rooms/detail/${JSON.parse(localStorage.getItem("userData")).roomId}`
    }
    // console.log(this.roomData);
    // console.log(body);
    this._roomService.notifyUser(body).subscribe(
      (res: any) => {
        // alert('A mail has been sent to the property user.')
        this._modalService.dismissAll();

        this.auth.checkCredits();

        this._randomService.setLoaderStatus(false);
        this._modalService.open(this.successPopup, {
          ariaLabelledBy: 'sendMessage',
          centered: true,
          size: 'sm'
        }).result.finally(
          () => {

          })
      },
      err => {
        console.log(err);
        this._randomService.setLoaderStatus(false);
      }
    )
  }

  openRoomDetail(roomDetail) {
    // console.log(roomDetail);
    this.router.navigate([`/rooms/detail/${roomDetail._id}`])
  }

  searchProperty() {
    console.log(this.searchForm.value);

    var searchParam = _(this.searchForm.value).omitBy(_.isUndefined).omitBy(_.isNull).omitBy(_.isEmpty).value();
    this.router.navigate(['/rooms/listing'], {
      queryParams: searchParam,
      queryParamsHandling: ''
    });
  }

  checkRoomVerificationStatus() {
    if(localStorage.getItem('userData')){
      return JSON.parse(localStorage.getItem('userData')).roomVerificationStatus;
    }
    else {
      return false;
    }
  }
}
