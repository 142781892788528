<div class="container-fluid">
    <form class="form_main mt-5 mb-5"  [formGroup]="signUpForm">
        <h3 class="mb-3 w-100 text-center">Sign Up</h3>
       <div class="row">
           <div class="col-6 ">
            <div class=" p-4 card form-light-yellow">
                <div class="row mb-3">
                    <div class="col-6">
                        <input type="text" class="form-control" placeholder="First Name" formControlName="firstName"/>
                    </div>
                    <div class="col-6">
                        <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <label for="">DOB</label>
                        <input type="date" class="form-control" placeholder="Date of Birth" formControlName="dob"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <input type="text" class="form-control" placeholder="Username" formControlName="username" />
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <input type="email" class="form-control" placeholder="Email address" formControlName="email"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <input type="text" class="form-control" placeholder="Contact Number" formControlName="mobile"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <input type="password" class="form-control" placeholder="Password" formControlName="password"/>
                    </div>
                    <!-- <div class="col-6">
                        <input type="text" class="form-control" placeholder="Confirm Password" />
                    </div> -->
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <label for="">Gender</label>
                        <select name="" id="" class="form-select" formControlName="gender" >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-6">
                        <select class="form-select" formControlName="currentUniversity">
                            <option value="">Select Current University</option>
                            <option *ngFor="let university of universityList" [value]="university.id">{{university.name}}</option>
                        </select>
                    </div>
                    <div class="col-6">
                        <select class="form-select" formControlName="destinationUniversity">
                            <option value="">Select Destination University</option>
                            <option *ngFor="let university of universityList" [value]="university.id">{{university.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
           </div>
           <div class="col-6 ">
            <div class="p-4 card form-light-yellow">
                <div class="row mb-3">
                    <label for="">Available from</label>
                    <div class="col-12">
                        <input type="date" class="form-control" placeholder="Available From" formControlName="availableFrom"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <input type="number" class="form-control" placeholder="Duration (in Months)" formControlName="duration"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <input type="number" class="form-control" placeholder="Room Rental" formControlName="roomListingPrice"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <textarea name="" class="form-control" id="" rows="4" formControlName="description" placeholder="Enter description of the room. This cannot be changed."></textarea>
                    </div>
                </div>
            </div>
           </div>
       </div>
        
        

        
        <div class="row my-3">
            <div class="col-12 text-center">
                <button class="btn btn-primary" type="button" [disabled]="!signUpForm.valid" (click)="signUpUser()">Create Account</button>
            </div>
        </div>
    </form>
</div>