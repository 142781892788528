<div class="spacer_30"></div>
<div class="spacer_30"></div>
<div class="room_detail_section">
    <div class="container mw_1440" *ngIf="roomDetail">
        <div class="room_detain_inner">
            <div class="row">
                <div class="col-4">
                    <div class="profile_left">
                        <label class="mb-3">
                            <div class="mb-2">
                                <img style="display: inline-block;width: 100%;max-width: 100px;" src="../../assets/images/user.png" alt="user" />
                            </div>
                            <span >
                                {{roomDetail.metadata['created-by'].gender}}
                            </span>
                        </label>
                        <div class="ui_data_1">
                            <p>Current University</p>
                            <strong>{{roomDetail.university}}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-8">
                    <div class="detail_room">
                        <div style="text-align: right;" class="mb-3">
                            <!-- <button type="button" disabled="true" class="btn btn_4 me-3"
                                        *ngIf="roomDetail.verified">Approved</button> -->
                            <button type="button" class="btn btn_2 me-3" (click)="openSendMessagePopup(sendRequest)" *ngIf="checkRoomVerificationStatus()">I am Interested</button>
                            <!-- <button type="button" class="btn btn_3 me-3" *ngIf="roomDetail.list"
                                        (click)="unlistProperty(roomDetail._id)">Unlist Property</button> -->
                        </div>
            
                        <div class="room_detail_ui_1">
                            <!-- <ul class="room_image_list" *ngIf="roomDetail.images">
                                        <li *ngFor="let roomImage of roomDetail.images">
                                            <img [src]="apiUrl + roomImage.url.substring(4)">
                                        </li>
                                    </ul> -->
                            <ngx-slick-carousel [config]="slideImageConfig" class="carousel" #slickModal="slick-carousel"
                                (init)="slickInit($event)">
                                <div ngxSlickItem *ngFor="let roomImage of roomDetail.images" class="slide">
                                    <div style="padding: 15px;">
                                        <div class="carousel_img_detail">
                                            <img [src]="apiUrl + roomImage.url.substring(4)">
                                        </div>
                                    </div>
                                </div>
                            </ngx-slick-carousel>
                        </div>
                        <div class="room_detail_ui_1">
                            <label for=""><Strong>Room Description</Strong></label>
                            <p>
                                {{roomDetail.description}}
                            </p>
                        </div>
                        <div class="room_detail_ui_1">
                            <div class="amenities_ui_1">
                                <ul>
                                    <!-- <li *ngFor="let roomAmenities of roomDetail.amenities" placement="top"
                                        [ngbTooltip]="roomAmenities.description"> -->
                                        <!-- {{roomAmenities.name}}</li> -->
                                        <ng-container *ngFor="let roomAmenities of roomDetail.amenities">
                                            <li *ngIf="roomAmenities.visibility === true">
                                                <img class="amenity_ui_img" [src]="apiUrl + roomAmenities.icon.url.substring(4)" alt="">{{roomAmenities.name}}
                                            </li>
                                        </ng-container>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <img class="room_detail_right" src="../../../assets/images/room_detail_right.png" alt="" />
    <img class="room_detail_left" src="../../../assets/images/room_detail_left.png" alt="" />
</div>

<ng-template #sendRequest let-close="close" let-d="dismiss" let-modal>
    <div class="modal-header">
        <h4 class="">Send Request</h4>
    </div>
    <div class="modal-body">
        <h2>Are you sure, you want to Notify this user?</h2>
        <div class="alert alert-info mt-3">
            <strong>Please Note:</strong> One Credit will Be deducted from your account.
        </div>
        <div>
            <button type="button" class="btn btn-primary me-3" (click)="NotifyUser()">Yes, Notify Now</button>
            <button type="button" (click)="close()" class="btn btn-danger">No</button>
        </div>
    </div>
</ng-template>