<div class="room_list_section">
    <div class="container mw_1440">
        <!-- <div class="spacer_30">
        </div>
        <div class="spacer_30">
        </div> -->
        <div class="spacer_30">
        </div>
        <div class="spacer_30">
        </div>
        <div class="rooms_listing">
            <div class="row mb-5">
                <div class="col-md-7">
                    <form  [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 mb-3">
                                <select name="" id="" class="form-select custom_input_1" formControlName="currentUniversity" >
                                    <option value="">Select Current University</option>
                                    <option *ngFor="let university of universityList" [value]="university.id">{{university.name}}</option>
                                    
                                </select>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <select name="" id="" class="form-select custom_input_1" formControlName="destinationUniversity" >
                                    <option value="">Select Destination University</option>
                                    <option *ngFor="let university of universityList" [value]="university.id">{{university.name}}</option>
                                </select>
                            </div>
                            <div class="col-lg-2 col-md-6 mb-3">
                                <select name="" id="" class="form-select custom_input_1" formControlName="gender" >
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                    <option value="">Gender</option>
                                </select>
                            </div>
                            <div class="col-lg-2 col-md-6">
                                <button type="button" class="btn btn_2" (click)="searchProperty()" >Search</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="roomsList_ui_1" *ngFor="let room of roomsList;index as i;">
                <div class="row">
                    <div class="col-md-4">
                        <div class="img_section">
                            <carousel [cellsToShow]="1" [autoplay]="false" [borderRadius]="15" [overflowCellsLimit]="2">
                                <div class="carousel-cell" *ngFor="let roomImage of room.images">
                                    <img [src]="apiUrl + roomImage.url.substring(4)"  >
                                </div>
                            </carousel>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="univ_detail">   
                            <h3>{{room.wing}}</h3>
                            <p class=""><b>Available From: </b>{{room.availableFrom}}</p>
                            <p class=""><b>Duration: </b>{{room.duration}} Months</p>
                            <p *ngIf="room.roomListingPrice" class=""><b>Price: </b>{{room?.roomListingPrice | currency:'SEK'}}</p>
                            <div class="amenities_ui_1">
                                <ul>
                                    <!-- <li *ngFor="let roomAmenities of room.amenities" placement="top" [ngbTooltip]="roomAmenities.description">{{roomAmenities.name}}</li> -->
                                    <ng-container *ngFor="let roomAmenities of room.amenities">
                                        <li *ngIf="roomAmenities.visibility === true">
                                            <img class="amenity_ui_img" [src]="apiUrl + roomAmenities.icon.url.substring(4)" alt="">{{roomAmenities.name}}
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                            <button type="button" class="btn btn_2 mt-3 me-3" (click)="openSendMessagePopup(room,sendRequest)" *ngIf="checkRoomVerificationStatus()">I am interested</button>
                            <button type="button" class="btn btn_2 mt-3" (click)="openRoomDetail(room)"> <i class="fa fa-eye"></i> View Detail</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 " style="text-align: center;">
                    <ngb-pagination
                        [(page)]='page'
                        [pageSize]='pageSize'
                        [collectionSize]="totalCount"
                        (pageChange)="onPageChange($event)">
                    </ngb-pagination>
                </div>
            </div>
        </div>
            <div class="alert alert-note p-3 no_result"  *ngIf="roomsList && roomsList.length <= 0">
                <p>Oops! We Coudn't find any room for this search.</p>
                <p>Please have a look at other Rooms you might like. </p>
                <button class="btn btn_1 mt-3" type="button" routerLink="/rooms">Show All Rooms</button>
            </div>
    </div>
    <img class="rl_top" src="../../../assets/images/rl_top.png" alt="rl_top" />
    <img  class="rl_bot" src="../../../assets/images/rl_bot.png" alt="rl_bot" />
</div>

<ng-template #sendRequest let-close="close" let-d="dismiss" let-modal>
    <div class="modal-header">
        <h4 class="">Send Request</h4>
    </div>
    <div class="modal-body">
        <h2>Are you sure, you want to Notify this user?</h2>
        <div class="alert alert-info mt-3">
            <strong>Please Note:</strong> One Credit will Be deducted from your account.
        </div>
        <div>
            <button type="button" class="btn btn-primary me-3" (click)="NotifyUser()">Yes, Notify Now</button>
            <button type="button" (click)="close()" class="btn btn-danger">No</button>
        </div>
    </div>
</ng-template>


<ng-template #successPopup let-close="close" let-d="dismiss" let-modal>
    <div class="modal-header">
        <h4 class="">Success</h4>
    </div>
    <div class="modal-body " style="text-align: center;">
        <p>Hurray ! A mail has been sent to the Room owner</p>
        <div class="mt-3">
            <button type="button" class="btn btn-primary me-3" (click)="close()">Close</button>
        </div>
    </div>
</ng-template>