import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { RandomService } from '../services/random.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  constructor(private fb: FormBuilder, private _authService: AuthService, private _randomService: RandomService, public router: Router) { }
  signUpForm = this.fb.group({
    username: ['', [Validators.required]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    dob: ['', [Validators.required]],
    gender: ['', [Validators.required]],
    description: ['', [Validators.required]],
    currentUniversity: ['', [Validators.required]],
    destinationUniversity: ['', [Validators.required]],
    availableFrom: ['', [Validators.required]],
    roomListingPrice: ['', [Validators.required]],
    duration: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    mobile: ['', [Validators.required]],
    password: ['', [Validators.required]]
  });
  get loginFormControl() { return this.signUpForm.controls }
  universityList;
  getUniversityList() {
    this._randomService.getUniversityList().subscribe(
      (res: any) => {
        console.log(res);
        this.universityList = res.universities;
      }
    )
  }

  signUpUser() {
    let formData = this.signUpForm.value;
    var body ={
      user: {
        username: formData.username,
        name: formData.firstName + ' ' + formData.lastName,
        dob: formData.dob,
        currentUniversity: formData.currentUniversity,
        destinationUniversity: formData.destinationUniversity,
        email: formData.email,
        mobile: formData.phone,
        password: formData.password,
        gender: formData.gender,
      },
      room: {
        university: formData.currentUniversity,
        description: formData.description,
        availableFrom: formData.availableFrom,
        duration: formData.duration,
        roomListingPrice: formData.roomListingPrice,
        gender: formData.gender,
      }
    }
    console.log(body);
    this._authService.signUpUser(body).subscribe(
      (res: any) => {
        // console.log('User Added');
        // this.signUpForm.reset();
        alert('Signup Successfull! Please login to proceed.')
        this.router.navigate(['/login']);
      },
      err => {
        console.log(err);
        alert('Oops! Something went wrong.')
        alert(err);
      }
    )
  }
  
  

  ngOnInit(): void {
    this.getUniversityList();
  }

}
