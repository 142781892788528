<!-- <nav class=" nav_custom navbar navbar-expand-lg navbar-light">
    <div class="container mw_1440">
        <a class="navbar-brand" routerLink="/">
            Room Swap
        </a>
            <ul class=" nav_listing me-auto">
                <li class="listing_ui">
                    <a class="anchor_link" aria-current="page" routerLink="/home" href="javascript:void(0);">Home</a>
                </li>
                <li class="listing_ui">
                    <a class="anchor_link" routerLink="/about-us" href="javascript:void(0);">About Us</a>
                </li>
                <li class="listing_ui">
                    <a class="anchor_link"  routerLink="/rooms" aria-current="page" href="javascript:void(0);">Find Room</a>
                </li>
                <li class="listing_ui" *ngIf="isLoggedIn && userType == 'USER'">
                    <a class="anchor_link" routerLink="/edit-room" aria-current="page" href="javascript:void(0);">Your Room</a>
                </li>
                <li class="listing_ui">
                    <a class="anchor_link" routerLink="/contact" >Contact</a>
                </li>
                <li class="listing_ui"  *ngIf="isLoggedIn && userType === 'USER'">
                    <a class="anchor_link" routerLink="/plans" >Plans</a>
                </li>
                <li class="listing_ui" *ngIf="isLoggedIn && userType != 'USER'">
                    <a class="anchor_link" routerLink="/admin" href="javascript:void(0);">Admin</a>
                </li>
            </ul>
            <div class="d-flex buttons_list_head">
                <button class="btn btn_1" type="button" routerLink="/login" *ngIf="!isLoggedIn">Sign In</button>
                <button class="btn btn_2" type="button"  routerLink="/signup" *ngIf="!isLoggedIn" >Free Room Listing</button>
                <button class="btn btn_2" type="button" *ngIf="isLoggedIn" (click)="logoutUser()">Logout</button>
                <span *ngIf="isLoggedIn && userType == 'USER'"> <strong>Credits Available : </strong> {{credits}}</span>
            </div>
    </div>
</nav> -->

<nav class="navbar navbar-expand-lg navbar-light">
    <div class="container">
    <a class="navbar-brand" routerLink="/">
        <img src="../../assets/images/logo.png" alt="logo" style="display: inline-block;max-width: 100px;width: 100%;height: 60px;object-fit: contain;" />
    </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="nav_listing navbar-nav me-auto mb-2 mb-lg-0">
            <li class="listing_ui nav-item">
                <a class="anchor_link nav-link" aria-current="page" routerLink="/home" href="javascript:void(0);">Home</a>
            </li>
            <li class="listing_ui nav-item">
                <a class="anchor_link nav-link" routerLink="/about-us" href="javascript:void(0);">About Us</a>
            </li>
            <li class="listing_ui nav-item">
                <a class="anchor_link nav-link"  routerLink="/rooms" aria-current="page" href="javascript:void(0);">Find Room</a>
            </li>
            <li class="listing_ui nav-item" *ngIf="isLoggedIn && userType == 'USER'">
                <a class="anchor_link nav-link" routerLink="/edit-room" aria-current="page" href="javascript:void(0);">Your Room</a>
            </li>
            <li class="listing_ui nav-item">
                <a class="anchor_link nav-link" routerLink="/contact" >Contact</a>
            </li>
            <li class="listing_ui nav-item"  *ngIf="isLoggedIn && userType === 'USER'">
                <a class="anchor_link nav-link" routerLink="/plans" >Plans</a>
            </li>
            <li class="listing_ui nav-item" *ngIf="isLoggedIn && userType != 'USER'">
                <a class="anchor_link nav-link" routerLink="/admin" href="javascript:void(0);">Admin</a>
            </li>
        </ul>
        <div class="d-flex buttons_list_head">
            <button class="btn btn_1" type="button" routerLink="/login" *ngIf="!isLoggedIn">Sign In</button>
            <button class="btn btn_2" type="button"  routerLink="/signup" *ngIf="!isLoggedIn" >Free Room Listing</button>
            <button class="btn btn_2" type="button" *ngIf="isLoggedIn" (click)="logoutUser()">Logout</button>
            <span *ngIf="isLoggedIn && userType == 'USER'"> <strong>Credits Available : </strong> {{credits}}</span>
        </div>
      </div>
    </div>
  </nav>