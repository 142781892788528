import { Component, OnInit } from '@angular/core';
import { RandomService } from '../services/random.service';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { PaymentService } from '../services/payment.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pay-now',
  templateUrl: './pay-now.component.html',
  styleUrls: ['./pay-now.component.scss'],
})
export class PayNowComponent implements OnInit {
  stripePromise = loadStripe(environment.stripeKey);
  isCompleted = false;

  constructor(
    private _randomService: RandomService,
    private _paymentService: PaymentService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      res=>{
        // console.log(res);
        const body = {
          user: JSON.parse(localStorage.getItem('userData')).username,
          packName: this.route.snapshot.params['orderId'],
          successUrl: window.location.origin + '/pay-success',
          cancelUrl: window.location.origin + '/pay-failed',
          paymentMethod: res.paymentMethod
        };
        this._paymentService.getSession(body).subscribe(
          (session:any) => {
            if (session) {
              console.log(session);
              window.location.href = session.checkoutUrl;
              // this.checkout(session['stripeSessionId']);
            } 
            else {
              this.isCompleted = true;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    )
    
  }

  // async checkout(sessionId) {
  //   const stripe = await this.stripePromise;
  //   const result = await stripe.redirectToCheckout({
  //     sessionId,
  //   });
  // }
}
