<section class="section_hero">
    <div class="container mw_1440">
        <div class="row">
            <div class="col-md-8">
                <div style="position: relative;z-index: 1;">
                    <h4 class="heading_main">
                        Find Your Room
                    </h4>
                    <form  [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-sm-5 mb-3">
                                <select name="" id="" class="form-select custom_input_1" formControlName="currentUniversity" >
                                    <option value="">Select Current University</option>
                                    <option *ngFor="let university of universityList" [value]="university.id">{{university.name}}</option>
                                    
                                </select>
                            </div>
                            <div class="col-sm-5 mb-3">
                                <select name="" id="" class="form-select custom_input_1" formControlName="destinationUniversity" >
                                    <option value="">Select Destination University</option>
                                    <option *ngFor="let university of universityList" [value]="university.id">{{university.name}}</option>
                                </select>
                            </div>
                            <div class="col-sm-2 mb-3">
                                <select name="" id="" class="form-select custom_input_1" formControlName="gender" >
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                    <option value="">Gender</option>
                                </select>
                            </div>
                            <div class="col-12">
                                <button type="button" class="btn btn_2" (click)="searchProperty()" [disabled]="!searchForm.valid"> <i class="fa fa-search"></i> Search</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-6">

            </div>
        </div>
    </div>
    <img class="girl_image" src="../../assets/images/8.png" alt="girl_image" />
</section>

<section class="section_website_detail">
    <div class="container">
        <div class="row">
            
            <div class="col-sm-4 mb-3 first_card">
                <div class="card_ui_2">
                    <img src="../../assets/images/4.png" alt="" />
                    <h5>FIND YOUR ROOM</h5>
                    <p>Find accommodation across the World.</p>
                </div>
            </div>
            <div class="col-sm-4 mb-3">
                <div class="card_ui_2">
                    <img src="../../assets/images/2.png" alt="" />
                    <h5>MEET YOUR SWAPMATES</h5>
                    <p>Browse through multiple potential housing options.</p>
                </div>
            </div>
            <div class="col-sm-4 mb-3">
                <div class="card_ui_2">
                    <img src="../../assets/images/3.png" alt="" />
                    <h5>100% VERIFIED STUDENTS</h5>
                    <p>
                        We validate all students before onboarding.
                    </p>
                </div>
            </div>

        </div>
    </div>
    <img class="bg_1" src="../../assets/images/bg_1.png" alt="bg_1" />
</section>

<section class="section_rooms_carousel">
    <div class="container mw_1440">
        <div class="heading_center_container mob_heading_center_container">
            <h5>Rooms</h5>
        </div>
        <div class="carousel_room_outer">
            <div class="container_room_carousel_outer">
                <!-- <carousel [cellsToShow]="3" [autoplay]="false" [height]="">
                    <div class="carousel-cell" *ngFor="let room of roomsList">
                        <div class="card_ui_3">
                            <img [src]="apiUrl + room.images[0].url.substring(4)" class="property_image" alt="" />
                            <h5 class="university_name">{{room.wing}}</h5>
                        </div>
                    </div>
                </carousel> -->
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)">
                    <div ngxSlickItem class="slide" *ngFor="let room of roomsList">
                        <a style="display: inline-block;width:100%;" routerLink="/rooms/detail/{{room._id}}">
                            <div class="card_ui_3">
                                <img *ngIf="room.images" [src]="apiUrl + room.images[0].url.substring(4)" class="property_image" alt="" />
                                <img *ngIf=" !room.images" src="https://via.placeholder.com/300" class="property_image" alt="" />
                                <h5 class="university_name">{{room.wing}}</h5>
                            </div>
                        </a>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
        <img class="room_section_img_left" src="../../assets/images/7.png" alt="room_img_left" />
        <!-- <img class="room_section_img_right" src="../../assets/images/10.png" alt="room_img_left" /> -->
    </div>
</section>

<section class="section_how_it_works">
    <div class="container mw_1440">
        <div class="heading_center_container mob_heading_center_container">
            <h5>This is how it works</h5>
        </div>
        <img class="how_it_works_image" src="../../assets/images/bg_img.png" alt="how_it_works_image" />
        <!-- <img class="room_section_img_right" src="../../assets/images/10.png" alt="room_img_left" /> -->
    </div>
</section>

<section class="section_home_bottom">
    <div class="container mw_1440">
        <div style="position: relative;z-index: 1;">
            <!-- <div class="heading_center_container mob_heading_center_container">
                <h5>Why us ?</h5>
            </div>
            <div class="why_us_listing">
                <ul>
                    <li>Search a site where only fellow students are found.</li>
                    <li>A platform made for students by students.</li>
                    <li>
                        Student-friendly price.
                    </li>
                </ul>
            </div> -->
            <div style="display: inline-block; width: 100%;text-align: center;">
                <img src="../../assets/images/why_us.png" alt="ok" style="display: inline-block; width: 100%; max-width: 800px;" />
            </div>
        </div>
    </div>
    <img class="home_last" src="../../assets/images/9.png" alt="home_last" />
    <img class="ellipse_5" src="../../assets/images/ellipse5.svg" alt="home_last" />
</section>