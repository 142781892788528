<section class="about_section text-center">
    <div class="container mw_1440">
        <div *ngIf="creditPacks">
            <div>
                <h4 class="my-5">Select your plan</h4>
                <div class="row">
                    <div class="col-md-4 mb-3" *ngFor="let pack of creditPacks | keyvalue">
                        <label class="card_ui_pack">
                            <input type="radio" name="plan" [id]="pack.key" (change)="onPlanChange(pack.key)" />
                            <div class="card text-center ">
                                <p class="inner_detail">
                                    <span class="w-100 d-block packName">
                                        {{pack.value.name}}
                                    </span>
                                    <span class="w-100 d-block">{{pack.value.price | currency:'SEK'}}</span>
                                </p>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div>
                <h4 class="my-5">Select a payment method</h4>
                <ul class="select_pay_method">
                    <li>
                        <label for="paypal">
                            <input type="radio" name="payMethod" id="paypal" (click)="payMethodChange('PAYPAL')" />
                            <img class="payment-image" src="../../assets/images/paypal.png" alt="PayPal Logo" />
                        </label>
                        <!-- <span>Pay by Paypal</span> -->
                    </li>
                    <li>
                        <label for="stripe">
                            <input type="radio" name="payMethod" id="stripe" (click)="payMethodChange('STRIPE')" />
                            <img class="payment-image" src="../../assets/images/stripe.png" alt=" " />
                        </label>
                        <!-- <span>Pay by Stripe</span> -->
                    </li>
                </ul>
            </div>
            <div class="col-12 my-5 text-center">
                <button type="button" class="btn btn_2 mb-4"
                    [disabled]="(selectedPlan === '' || selectedPlan === undefined) || (paymentMethod === '' || paymentMethod === undefined)"
                    (click)="onPay()">Pay Now</button>
            </div>
        </div>
    </div>
</section>