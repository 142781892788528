import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { AuthenticationService } from '../services';
// import Swal from 'sweetalert2';
import { Router } from '@angular/router';
// import { ToastService } from './toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // if (err.status === 0) {
            //     this._toastService.show('Something Went Wrong!', {
            //         classname: 'toast_ui alert_danger text-light',
            //         delay: 2000,
            //         autohide: true
            //     });
            // }
            if (err.status === 401) {
                // this._toastService.show('Something Went Wrong!', {
                //     classname: 'toast_ui alert_danger text-light',
                //     delay: 2000,
                //     autohide: true
                // });
                localStorage.clear();
                this.router.navigate(['/login']);
            }
            // if(err.status === 403){
            //     this._toastService.show(err.error.message, {
            //         classname: 'toast_ui alert_danger text-light',
            //         delay: 2000,
            //         autohide: true
            //     });
            //     // this.router.navigate([''])
            // }
            // else if ([504].indexOf(err.status) !== -1) {
            //     this._toastService.show('Network connection is low', {
            //         classname: 'toast_ui alert_danger text-light',
            //         delay: 2000,
            //         autohide: true
            //     });

            // }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}